import Vue from 'vue'
import ElementUI from 'element-ui';
/**
 * @description 统一格式化去除input首尾空格
 * @date 2024-6-13
 */
Vue.component('el-input', {
	extends: ElementUI.Input,
	created() {
		this.$on('change', (value) => {
			this.$emit('input', value.trim())
			// this.$emit('input', value.replace(
			// 	/[<>]/g,
			// 	'').replace(/\s/g, ""))
		})
	}
})