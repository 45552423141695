import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [{
		path: '/',
		component: () => import('@/views/Main'),
		children: [{
				path: '/',
				name: 'home',
				component: () => import('@/views/Home'),
			}, {
				path: '/company',
				component: () => import('@/views/company/index'),
			},
			{
				path: '/company/detail',
				component: () => import('@/views/company/detail')
			}, {
				path: '/company/info',
				component: () => import('@/views/company/info'),
			}, {
				path: '/process',
				component: () => import('@/views/process'),
			}, {
				path: '/factory',
				component: () => import('@/views/factory/index'),
			},
			{
				path: '/factory/info',
				component: () => import('@/views/factory/info'),
			},
			{
				path: '/factory/detail',
				component: () => import('@/views/factory/detail'),
			}, {
				path: '/order',
				component: () => import('@/views/order/index'),
			}, {
				path: '/coupons',
				component: () => import('@/views/coupons/index'),
			},{
				path: '/coupons/detail',
				component: () => import('@/views/coupons/detail'),
			},
			{
				path: '/order/detail',
				component: () => import('@/views/order/detail'),
			}, {
				path: '/order/edit',
				component: () => import('@/views/order/edit'),
			}, {
				path: '/contract',
				component: () => import('@/views/contract/index'),
			},
			{
				path: '/statistics/chart',
				component: () => import('@/views/statistics/chart'),
			},
			{
				path: '/statistics/report',
				component: () => import('@/views/statistics/report'),
			},
			{
				path: '/statistics/company',
				component: () => import('@/views/statistics/company'),
			},
			{
				path: '/sys/basicSet',
				component: () => import('@/views/sys/basicSet'),
			},
			{
				path: '/sys/messageSet',
				component: () => import('@/views/sys/messageSet'),
			},
			{
				path: '/sys/edSet',
				component: () => import('@/views/sys/edSet'),
			},
			{
				path: '/sys/authSet',
				component: () => import('@/views/sys/authSet'),
			}, {
				path: '/sys/log',
				component: () => import('@/views/sys/log'),
			}, {
				path: '/sys/log_zc',
				component: () => import('@/views/sys/log_zc'),
			}, {
				path: '/sys/log_pt',
				component: () => import('@/views/sys/log_pt'),
			},{
				path: '/sys/autoshenhe',
				component: () => import('@/views/sys/autoshenhe'),
			},{
				path: '/sys/autojiagong',
				component: () => import('@/views/sys/autojiagong'),
			},{
				path: '/sys/imgset',
				component: () => import('@/views/sys/imgset/index'),
			},
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login')
	},
	{
		path: '/adminlogin',
		name: 'adminlogin',
		component: () => import('@/views/adminlogin')
	},
	{
		path:'/screen',
		name: 'screen',
		component: () => import('@/views/screen')
	}
]

const router = new Router({
	routes
})


export default router