import axios from 'axios'
import router from "../router";
import {
	Loading
} from 'element-ui'
import {
	getToken,
	removeToken
} from '@/utils/auth.js'
import {
	Message
} from "element-ui";
import store from '@/store/index.js'
// 创建axios实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 500000,
	withCredentials: true,
})
// 请求拦截器
service.interceptors.request.use(
	config => {
		// console.log(config)
		if (config.method == 'get') {
			// config.params['token'] = getToken()
		} else if (config.method == 'post') {
			config.data['token'] = getToken()
		} else if (config.method == 'delete') {
			config.url = config.url + '?token=' + getToken()
		}
		// config.headers['token'] = getToken()
		// config.params['token'] = getToken()
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(
	(response) => {
		const res = response.data;
		// 请求成功，返回数据
		if (res.status === 1 || res.status==true) {
			return res;
		} else {
			Loading.service().close();
			// 根据状态码提示错误信息
			switch (res.status) {
				case 2:
					// 无权限，跳转到登录页面并清除token
					// localStorage.removeItem("token");
					removeToken();
					Message({
						message: '登录已过期，请重新登录',
						type: "error",
					});
					if (store.state.userType == 1) {
						router.replace("/adminlogin");
					} else {
						router.replace("/login");
					}
					break;
				case 403:
					Message({
						message: res.msg,
						type: "error",
					});
					break;
				case 404:
					alert("请求的资源不存在");
					// todo: 这里可以跳转到404页面
					break;
				case 0:
					Message({
						message: res.msg,
						type: "error",
					});
					return Promise.reject(res.msg || "Error");
					break;
				case false:
					Message({
						message: res.msg,
						type: "error",
					});
					break;
			}
			return Promise.reject(res.msg || "Error");
		}
	},
	(error) => {
		Loading.service().close();
		Message({
			message: error,
			type: "error",
		});
		return Promise.reject(error);
	}
);
export default service