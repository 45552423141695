import Vue from 'vue'
import Vuex from 'vuex'
import http from '@/utils/request.js'
Vue.use(Vuex)
const store = new Vuex.Store({
	state: {
		userType: localStorage.getItem('userType') || null,
		hasPermission: [],
		company_id: localStorage.getItem('company_id') || null,
		total_num: localStorage.getItem('total_num') || null,
		shengyu: localStorage.getItem('shengyu') || null,
	},
	getters: {},
	mutations: {
		userLogin(state, userinfo) {
			localStorage.setItem('userType', userinfo)
			state.userType = userinfo
		},
		setCompanyId(state, data) {
			localStorage.setItem('company_id', data)
			state.company_id = data
		},
		userLogout(state) {
			localStorage.removeItem('userType')
			localStorage.removeItem('total_num')
			localStorage.removeItem('shengyu')
			localStorage.removeItem('company_id')
			localStorage.removeItem('userSetInfo')
			state.userType = null
			state.company_id = null
			state.total_num = null
			state.shengyu = null
			state.hasPermission = []
		},
		sethasPermission(state, content) {
			state.hasPermission = content
		},
		setCzq(state, content) {
			// console.log(content)
			localStorage.setItem('total_num', content.total_num)
			localStorage.setItem('shengyu', content.shengyu)
			state.total_num = content.total_num
			state.shengyu = content.shengyu
		},
	},
	actions: {
		userCzqAction(context) {
			http.post(`/api/coupons/corp_coupons`, {}).then(res => {
				context.commit('setCzq', res.result)
			})
		},
	},
})
export default store