import Vue from 'vue'
import App from './App.vue'
import Cookies from 'js-cookie'
import ElementUI from 'element-ui'
import http from '@/utils/request.js'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.css'
import './assets/css/font.css'
import './assets/font/iconfont.css'
import './assets/iconfont/iconfont.css'
import router from './router'
import store from '@/store/index.js'
import scroll from 'vue-seamless-scroll'
import "@/utils/el-input"//input 去除前后空格
Vue.use(scroll)
import {
	hasPermission
} from '@/utils/common'
Vue.use(ElementUI);

// 封装全局loading方法
Vue.prototype.$loading = (options = {}) => {
	const defaultOptions = {
		lock: true,
		text: '加载中...',
		background: 'rgba(0, 0, 0, 0.5)'
	};
	const fullOptions = {
		...defaultOptions,
		...options
	};
	return ElementUI.Loading.service(fullOptions);
};

// 封装关闭全局loading方法
Vue.prototype.$closeLoading = () => {
	ElementUI.Loading.service().close();
};

Vue.prototype.$http = http
Vue.prototype.$store = store
Vue.prototype.$hasPermission = hasPermission
Vue.config.productionTip = false
// 路由守卫
router.beforeEach((to, from, next) => {
	const token = Cookies.get("Token");
	if (!token && to.path !== "/login" && to.path !== "/adminlogin") {
		next({
			path: "/login"
		});
	} else if (token && (to.path === "/login" || to.path === "/adminlogin")) {
		next({
			name: "home"
		});
	} else {
		next();
	}
});
new Vue({
	router,
	render: h => h(App),
}).$mount('#app')